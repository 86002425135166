import React from "react"
import styled from "styled-components"
import { H2 } from "../headings"

const Wrapper = styled.div`
  background: #101010;
  color: #D3D3D3;
  text-align: center;

  section {
    max-width: 1300px;
    margin: 0 auto;
    
    padding: 16px;
    padding-bottom: 64px;
    padding-top: 0;

    div {
      text-align: left;
      max-width: 900px;
      margin: 0 auto;
    }

    @media screen and (max-width: 768px) {
      padding-left: 0;
      padding-right: 0;

      #bio-container {
        padding-left: 16px;
        padding-right: 16px;
      }
    }

    h2 {
      color: #F5F5F5;
      background: #101010;
  
      @media screen and (max-width: 768px) {
          width: 100%;
          padding-top: 32px;
      }
    }
  }
`
export default ({ data }) => {
  return (
    <Wrapper>
      <section>
        <H2 id="bio">Biography</H2>
        <div
          id="bio-container"
          dangerouslySetInnerHTML={{
            __html: data.html,
          }}
        />
      </section>
    </Wrapper>
  )
}
