import React from "react"
import { graphql } from "gatsby"

import Header from "../components/header"
import Footer from "../components/footer"
import HomePage from "../components/sections/home"
import GalleryPage from "../components/sections/gallery"
import BioPage from "../components/sections/bio"
import ResumePage from "../components/sections/resume"
import ContactPage from "../components/sections/contact"

const IndexPage = ({ data }) => {
  return (
    <main>
      <Header data={data.settingsPage} />

      <HomePage data={data.homePage} title={data.settingsPage.frontmatter.siteTitle} />
      <GalleryPage data={data.galleryPage} />
      <BioPage data={data.bioPage} />
      <ResumePage data={data.resumePage} />
      <ContactPage data={data.settingsPage} />

      <Footer data={data.settingsPage} />
    </main>
  )
}

export default IndexPage

export const pageQuery = graphql`
  {
    settingsPage: markdownRemark(fields: { slug: { eq: "/settings/" } }) {
      frontmatter {
        siteTitle
        siteUrl
        description
        keywords
        imdb
        twitter
        linkedin
        instagram
        facebook
        youtube
      }
    }
    homePage: markdownRemark(fields: { slug: { eq: "/home/" } }) {
      frontmatter {
        video
        videoTitle
        avatar {
          childImageSharp {
            fixed(width: 200, height: 200) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        soundCloudUrl
      }
      html
    }
    galleryPage: markdownRemark(fields: { slug: { eq: "/gallery/" } }) {
      html
      frontmatter {
        images {
          childImageSharp {
            fluid(maxWidth: 1300) {
              ...GatsbyImageSharpFluid
            }
          }
          name
          id
        }
      }
    }
    bioPage: markdownRemark(fields: { slug: { eq: "/bio/" } }) {
      html
    }
    resumePage: markdownRemark(fields: { slug: { eq: "/resume/" } }) {
      frontmatter {
        image {
          childImageSharp {
            fluid(maxWidth: 1300) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        pdf {
          publicURL
        }
      }
    }
  }
`
