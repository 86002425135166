import styled from "styled-components"

const H2 = styled.h2`
  min-width: 25%;
  display: inline-block;
  position: relative;
  top: -32px;
  margin:0;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  text-align: center;
  text-transform: uppercase;
  border-radius: 2px 2px 0 0;
`

export {
  H2
};