import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import { H2 } from "../headings"

const Wrapper = styled.div`
  background: #202020;
  
  section {
    max-width: 1300px;
    margin: 0 auto;
    
    padding: 16px 0;
    padding-bottom: 64px;
    padding-top: 0;
    
    text-align: center;
    
    div.gatsby-image-wrapper {
      display: inline-block;
      margin: 0;
      padding: 0;
      vertical-align: middle;
      width: 25%;
      border: 16px solid #202020; 

      img {
        margin: 0 !important;
        transition: opacity 0.5s ease 0.5s, padding 0.25s !important;
        color: transparent !important;
        
        &:hover {
          padding: 8px;
          cursor: zoom-in;
        }
      }

      @media screen and (max-width: 1000px) {
        width: 33.33%;
      }
      @media screen and (max-width: 750px) {
        width: 50%;
      }
      @media screen and (max-width: 450px) {
        width: 100%;

        border: 8px solid #202020; 
      }
    }

    h2 {
      color: #F5F5F5;
      background: #202020;

      @media screen and (max-width: 768px) {
        width: 100%;
        padding-top: 32px;
      }
    }
  }
`


const Images = ({data}) => {
  const images = data.frontmatter.images;
  return (
    <div>
      {images.map((image) =>
        <Img key={image.id} fluid={image.childImageSharp.fluid} alt={image.name} />
      )}
    </div>
  );
}

export default class Gallery extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      hide: true,
      imgSrc: null
    };

    this.clickHandler = this.clickHandler.bind(this);
  }

  clickHandler(e) {
    e.preventDefault();
    if (e.target.src && this.state.hide) {
      this.setState({
        hide: false,
        imgSrc: e.target.src
      })
    }
    else {
      this.setState({
        hide: true,
        imgSrc: null
      })
    }
  }

  renderImages() {
    return this.props.data.frontmatter.images
      
  }

  render() {
    return (
      <Wrapper onClick={this.clickHandler}>
        <div id="popupWrapper" style={{display: this.state.hide ? 'none' : 'block'}}>
          <div id="popup">
            <img src={this.state.imgSrc} alt="" />
          </div>
        </div>
        <section>
          <H2 id="gallery">Gallery</H2>
          <Images data={this.props.data} />
        </section>
      </Wrapper>
    )
  }
}
