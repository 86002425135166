import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import { H2 } from "../headings"
import Button from "../button"

const Wrapper = styled.div`
  background: #202020;
  color: #D3D3D3;
  text-align: center;

  section {
    max-width: 1300px;
    margin: 0 auto;

    padding: 16px;
    padding-bottom: 96px;
    padding-top: 0;

    div {
      max-width: 900px;
      margin: 0 auto;
    }

    div#imgWrapper {
      width: 100%;
      margin-top: 0;
      margin-bottom: 32px;
      opacity: 0.85;
      transition: 0.5s ease 0.15s opacity;
      cursor: pointer;

      &:hover {
        opacity: 1;
      }
    }

    @media screen and (max-width: 768px) {
      padding-left: 0;
      padding-right: 0;

      #container-resume {
        padding-left: 16px;
        padding-right: 16px;
      }
    }

    h2 {
      color: #F5F5F5;
      background: #202020;
  
      @media screen and (max-width: 768px) {
          width: 100%;
          padding-top: 32px;
      }
    }
  }
`
export default ({ data }) => {
  return (
    <Wrapper>
      <section>
        <H2 id="resume">Resume</H2>
        <div id="container-resume">
          <div id="imgWrapper"> 
            <a href={data.frontmatter.pdf.publicURL} title="Download resume file">
              <Img fluid={data.frontmatter.image.childImageSharp.fluid} />
            </a>
          </div>
          <a href={data.frontmatter.pdf.publicURL} title="Download resume file">
            <Button>Download Resume</Button>
          </a>
        </div>
      </section>
    </Wrapper>
  )
}
