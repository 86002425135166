import React from "react"
import styled from "styled-components"

const Iframe = styled.iframe`
  width: 100%;
  margin: 0;
  padding: 0;
  top: 0;
  position: absolute;
  background: black;
`;

export default ({ videoSrcURL, videoTitle, ...props }) => (
  <Iframe
    src={videoSrcURL}
    title={videoTitle}
    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    frameBorder="0"
    webkitallowfullscreen="true"
    mozallowfullscreen="true"
    allowFullScreen
    scrolling="no"
  />
)
