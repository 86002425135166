import React from "react"
import styled from "styled-components"
import { H2 } from "../headings"
import Button from "../button"
import Socials from "../socials"

const RequiredWrapper = styled.span`
  color: rgb(0, 173, 239);
  font-weight: bold;
`;
const Required = () => (
  <RequiredWrapper>*</RequiredWrapper>
);

const Form = styled.form`
  padding: 0;
  display: block;

  overflow: hidden;
  margin: 0 auto;
  color: #D3D3D3;

  max-width: 900px;
  
  label {
    width: 100%;
    display: inline-block;
    margin-bottom: 5px;
    text-align: left;
  }

  p {
    margin-bottom: 32px;
  }

  input, select, textarea {
    background: #202020;
    color: #F5F5F5;
    width: 100%;
    outline: none;
    border: 2px solid #202020;
    padding: 5px 10px;

    transition: 0.25s border;

    &:focus {
      border: 2px solid rgb(0, 173, 239);
    }
  }

  textarea {
    resize: vertical;
    min-height: 200px;
  }
`;

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const ContactForm = () => {
  const [state, setState] = React.useState({})
  const [hide, setHide] = React.useState(true)

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
    .then(() => {
      setHide(false)
      reset()
    })
    .catch((error) => alert(error))
  }

  const reset = () => {
    document.getElementById("contactForm").reset();
  }

  return (
    <Form
      id="contactForm"
      name="contact"
      method="post"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      onSubmit={handleSubmit}
    >
      {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
      <input type="hidden" name="form-name" value="contact" />
      <p hidden>
        <label>
          Don’t fill this out: <input name="bot-field" onBlur={handleChange} />
        </label>
      </p>
      <p>
        <label htmlFor="name">Name <Required /></label>
        <input type="text" name="name" id="name" onBlur={handleChange} required placeholder="Your first and last name" />
      </p>
      <p>
        <label htmlFor="email">Email <Required /></label>
        <input type="email" name="email" id="email" onBlur={handleChange} required placeholder="your@email.com" />
      </p>
      <p>
        <label htmlFor="message">Message <Required /></label>
        <textarea name="message" id="message" onBlur={handleChange} required placeholder="Your message" />
      </p>
      <p className={`alert ${hide ? "hide" : ""}`}>
        Thank you for the message! We will connect soon!
      </p>
      <p>
        <Button type="submit">Send Your Message</Button>
      </p>
    </Form>
  )
}

const Wrapper = styled.div`
  background: #101010;
  color: #D3D3D3;
  text-align: center;

  section {
    max-width: 1300px;
    margin: 0 auto;
    
    padding: 16px;
    padding-bottom: 32px;
    padding-top: 0;

    .socials {
      a {
        margin: 16px;
      }
      svg {
        margin: 0;
        font-size: 3em;
      }
    }

    @media screen and (max-width: 700px) {
      .socials {
        a {
          margin: 8px;
          margin-top: 0;
          margin-bottom: 16px;
        }
        svg {
          margin: 0;
          font-size: 2em;
        }
      }
    }

    @media screen and (max-width: 768px) {
      padding-left: 0;
      padding-right: 0;

      #contact-container {
        padding-left: 16px;
        padding-right: 16px;
      }
    }

    h2 {
      color: #F5F5F5;
      background: #101010;
  
      @media screen and (max-width: 768px) {
          width: 100%;
          padding-top: 32px;
      }
    }
  }
`
export default ({ data }) => {
  return (
    <Wrapper>
      <section>
        <H2 id="contact">Contact</H2>
        <div id="contact-container">
          <Socials data={data} />
          <ContactForm />
        </div>
      </section>
    </Wrapper>
  )
}
