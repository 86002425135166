import styled from "styled-components"

const Button = styled.button`
  border: none;
  width: 100%;
  padding: 16px;
  cursor: pointer;
  background: rgb(0, 143, 209);
  max-width: 450px;
  color: #fff;
  border: 1px solid rgb(0, 143, 209);
  text-transform: uppercase;
  border-radius: 2px;
  transition: 0.25s background;
  box-shadow: 0 3px 3px 0 rgba(0,0,0,.1);
  &:hover {
    background: rgb(0, 173, 239);
  }
`;

export default Button;