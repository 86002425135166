import React from "react"
import Img from "gatsby-image"
import { AnchorLink } from "gatsby-plugin-anchor-links";
import styled from "styled-components"
import Video from "../video"
import Button from "../button"

const Wrapper = styled.div`
  background: black;
  color: #D3D3D3;

  overflow: auto;
  padding-top: 16px;

  section {
    max-width: 1300px;
    margin: 0 auto;
    
    padding: 16px;
    padding-bottom: 32px;
  }

  @media screen and (max-width: 768px) {
    padding: 0;
    border-top: 2px solid #101010;
    section {
      padding: 0;
    }
  }
`
const TableContainer = styled.div`
  display: table;

  @media screen and (max-width: 768px) {
    display: block;
    width: 100%;
    overflow: auto;
  }
`
const IntroWrapper = styled.div`
  display: table-cell;

  width: 296px;
  min-height: 551px;
  overflow: auto;
  background: #202020;
  border-radius: 2px;

  margin: 0;
  
  padding: 16px;
 
  img {
    border-radius: 100px;
  }
  .gatsby-image-wrapper {
    margin-bottom: 16px !important;
  }

  text-align: center;

  p {
    margin-bottom: 16px;
  }

  ul {
    list-style-type: none;
    margin-left: 0;

    li {
      margin-bottom: 8px;
    }
  }

  button a {
    color: #fff;
    text-decoration: none;
  }

  @media screen and (max-width: 768px) {
    display: block;
    width: 100%;
    padding: 32px;
  }
`
const VideoWrapper = styled.div`
  display: table-cell;
  position: relative;
  width: 1010px;

  iframe {
    position: absolute;
    height: 100%; 
    width: 100%;
    border: none;
    padding-left: 32px;
    
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }
  
  @media screen and (max-width: 768px) {
    display: block;
    width: 100%;

    iframe {
      position: relative;

      height: 300px;
      padding: 0;
    }
  }
`
const IframeWrapper = styled.div`
  width: 100%;
  height: 150px;
  overflow: hidden;

  margin-top: 32px;
  margin-bottom: 32px;

  iframe {
    margin: 0;
  }

  @media screen and (max-width: 768px) {
    display: flex;
    align-items: center;
    background: #202020;
    border-bottom: 32px solid black;
    height: 262px;
    margin-top: 0;
  }
`

export default ({data, title}) => (
  <Wrapper>
    <section>
      <TableContainer>
        <IntroWrapper>
          <Img fixed={data.frontmatter.avatar.childImageSharp.fixed} />
          <div>
            <span
              dangerouslySetInnerHTML={{
                __html: data.html,
              }}
            />

            <AnchorLink to="/#contact" title="Connect with Cory" stripHash>
              <Button>Connect with Cory</Button>
            </AnchorLink>
          </div>
        </IntroWrapper>
        
        <VideoWrapper>
          <Video
            videoSrcURL={data.frontmatter.video}
            videoTitle={data.frontmatter.videoTitle}
          />
        </VideoWrapper>
      </TableContainer>
      <IframeWrapper>
          <iframe
            width="100%"
            height="166"
            scrolling="no"
            frameborder="no"
            allow="autoplay"
            src={`https://w.soundcloud.com/player/?url=${data.frontmatter.soundCloudUrl}&amp;buying=false&show_artwork=false&show_playcount=false&color=008fd1`}>
            </iframe>
        </IframeWrapper>
    </section>
  </Wrapper>
)